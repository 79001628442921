<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="!!data">
    <InputRadio
        v-model="data"
        :options="optionsUsage"
        :db="db"
        :args="args"
        :skipped="skipped"
        @skipped="skipped=$event"
        class="radio-options-wide"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers } from "../../../helpers/questionLoadHelpers";
import { mortgageSurveyOptions } from "../../../options/mortgageSurveyOptions";

import QuestionBase from "../../../QuestionBase";
import InputRadio from "../../../inputs/InputRadio";

export default {
  name: 'Usage',
  mixins: [mortgageSurveyOptions, questionLoadHelpers],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: String,
      required: false
    },
    type: {
      type: String,
      required: false
    },
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    },
    question() {
      return {
        title: `${this.type === 'Remortgage' ? 'How is the property used?' : 'How do you intend to use the property?'}`,
        subTitle: `${this.type === 'Remortgage' ? 'Choose the option that best describes how the property is currently used. Select \'Dependant Relative\' if a family member lives in the property.' : 'Choose the option that best describes your plans for the property. Select \'Dependant Relative\' if you will not live in the property by a family member will.'}`,
        tip: null
      }
    }
  },
  data () {
    return {
      db: {
        saveLocation: 'mortgage_survey_save',
        saveField: 'new_usage',
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>