<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Mortgage Requirements</h3>

    <Type
        v-model="form.type"/>

    <template
        v-if="form.type ==='Purchase'">

      <Usage
          v-model="form.new_usage"
          :type="form.type"/>

      <FTB
          v-if="form.new_usage !== 'Second Home'"
          v-model="form.ftb"/>

      <SelfBuild
          v-model="form.new_self_build"/>

      <RTB
          v-model="form.new_rtb"/>
      <div
          v-if="form.new_rtb"
          class="question-box with-arrow">

        <RTBDiscount
            v-model="form.new_rtb_discount"/>

      </div>

      <PropertyFound
          v-model="form.new_property_found"/>

      <div
          v-if="form.new_property_found"
          class="question-box with-arrow">

        <Timescale
            v-if="form.new_property_found === 'Still Looking'"
            v-model="form.new_timescale"/>

        <Value
            v-model="form.new_value"
            :property-found="form.new_property_found"
            :type="form.type"/>

        <Balance
            v-model="form.new_balance"
            :property-found="form.new_property_found"
            :type="form.type"/>

        <BuyToLetMonthlyRent
            v-if="form.new_usage === 'Buy to Let'" v-model="form.rent"
            :property-found="form.new_property_found" :type="form.type"/>

      </div>

      <EquityScheme
          v-if="form.new_usage ==='Residential'"
          :ftb="form.ftb"
          v-model="form.new_equity_loan"/>


      <div
          v-if="['Other Scheme', 'Help to Buy'].includes(form.new_equity_loan)"
          class="question-box with-arrow">
        <EquityLoanPercentage
            v-model="form.new_equity_loan_percentage"/>
      </div>


      <SharedOwnership
          v-if="form.new_usage === 'Residential' && !['Help to Buy', 'Other Scheme'].includes(form.new_equity_loan)"
          v-model="form.new_shared"/>

      <div v-if="form.new_shared"
           class="question-box with-arrow">
        <SharedOwnershipPercentage
            v-model="form.new_shared_percentage"/>

        <SharedOwnershipRent
            v-model="form.new_shared_rent"/>
      </div>

      <HasExistingProperty
          v-if="form.ftb === false && form.new_usage === 'Residential'"
          v-model="form.has_existing_property"/>


      <div class="question-box with-arrow"
          v-if="form.has_existing_property && form.ftb === false && ['Residential'].includes(form.new_usage)">

        <ExistingPropertyAddress
            v-model="form.existing_address"
            :form="form"/>

        <ExistingPropertyOwnership
            v-model="form.existing_owner"/>

        <ExistingPropertyValue
            v-model="form.existing_value"/>

        <ExistingPropertyStatus
            v-model="form.existing_status"/>

        <ExistingPropertyEncumbered
            v-model="form.existing_encumbered"/>

        <div class="question-box with-arrow"
             v-if="form.existing_encumbered">

          <ExistingPropertyBalance
              v-model="form.existing_balance"/>

          <ExistingPropertyLender
              v-model="form.existing_lender"/>
        </div>

        <ExistingPropertyEquityScheme
            v-model="form.existing_equity_loan"/>


        <div
            v-if="['Other Scheme', 'Help to Buy'].includes(form.existing_equity_loan)"
            class="question-box with-arrow">
          <ExistingPropertyEquityLoanPercentage
              v-model="form.existing_equity_loan_percentage"/>
        </div>

        <template v-if="form.existing_equity_loan === 'No'">

          <ExistingPropertyShared
              v-model="form.existing_shared"/>

          <div v-if="form.existing_shared"
               class="question-box with-arrow">

            <ExistingPropertySharedPercentage
                v-model="form.existing_shared_percentage"/>

            <ExistingPropertySharedRent
                v-model="form.existing_shared_rent"/>

          </div>
        </template>
      </div>

      <DepositSource
          v-model="form.new_deposit"/>

      <DepositSourceDetailsFamily
          v-if="form.new_deposit.includes('Gift (Family)')"
          v-model="form.new_deposit_gift_family_details" />

      <DepositSourceDetailsOther
          v-if="form.new_deposit.includes('Gift (Other)')"
          v-model="form.new_deposit_gift_other_details" />

      <Savings
          v-model="form.savings"/>

    </template>


    <template
        v-if="['Remortgage', 'Product Transfer'].includes(form.type)">

      <ExistingPropertyAddress
          v-model="form.existing_address"
          :form="form"/>

      <ExistingPropertyOwnership
          v-model="form.existing_owner"/>

      <Usage
          v-model="form.new_usage"
          :type="form.type"/>

      <Value
          v-model="form.new_value"
          :property-found="form.new_property_found"
          :type="form.type"/>

      <BuyToLetMonthlyRent
          v-if="['Buy to Let', 'Holiday Let'].includes(form.new_usage)"
          v-model="form.rent"/>

      <ExistingPropertyEncumbered
          v-model="form.existing_encumbered"/>

      <div
          v-if="form.existing_encumbered"
          class="question-box with-arrow">

        <ExistingPropertyBalance
            v-model="form.existing_balance"/>

        <ExistingPropertyLender
            v-model="form.existing_lender"/>

      </div>

      <Balance
          v-model="form.new_balance"
          :property-found="form.new_property_found"
          :type="form.type"/>

      <div
          v-if="form.new_balance > form.existing_balance"
          class="question-box with-arrow">

        <ExistingPropertyCapitalRaisingReason
            v-model="form.existing_capital_raising_reason"
            :existing-mortgage="form.existing_balance"
            :new-mortgage="form.new_balance"/>
      </div>

      <ExistingPropertyEquityScheme
          v-model="form.existing_equity_loan"/>


      <div
          v-if="['Other Scheme', 'Help to Buy'].includes(form.existing_equity_loan)"
          class="question-box with-arrow">

        <ExistingPropertyEquityLoanPercentage
            v-model="form.existing_equity_loan_percentage"/>

        <EquityScheme
            v-model="form.new_equity_loan"
            :type="form.type"/>

        <EquityLoanPercentage
            v-if="['Help to Buy', 'Other Scheme'].includes(form.new_equity_loan)"
            v-model="form.new_equity_loan_percentage"
            :type="form.type"/>

      </div>

      <template v-if="form.existing_equity_loan === 'No'">

        <ExistingPropertyShared
            v-model="form.existing_shared"/>

        <div v-if="form.existing_shared"
             class="question-box with-arrow">

          <ExistingPropertySharedPercentage
              v-model="form.existing_shared_percentage"/>

          <ExistingPropertySharedRent
              v-model="form.existing_shared_rent"/>

          <SharedOwnership
              v-model="form.new_shared"
              :type="form.type"/>

          <template v-if="form.new_shared">

            <SharedOwnershipPercentage
                v-model="form.new_shared_percentage"
                :type="form.type"/>

            <SharedOwnershipRent
                v-model="form.new_shared_rent"
                :type="form.type"/>

          </template>

        </div>
      </template>
    </template>
  </div>
</template>

<script>
import {fieldsetHelpers} from "../../fieldsetHelpers";
import {statusOptions} from "../../../question/options/statusOptions";

import Type from "../../../question/questions/mortgage/survey/Type";
import Usage from "../../../question/questions/mortgage/survey/Usage";
import FTB from "../../../question/questions/mortgage/survey/FTB";
import PropertyFound from "../../../question/questions/mortgage/survey/PropertyFound";
import Value from "../../../question/questions/mortgage/survey/Value";
import Balance from "../../../question/questions/mortgage/survey/Balance";
import EquityScheme from "../../../question/questions/mortgage/survey/EquityScheme";
import SharedOwnership from "../../../question/questions/mortgage/survey/SharedOwnership";
import SharedOwnershipPercentage from "../../../question/questions/mortgage/survey/SharedOwnershipPercentage";
import SharedOwnershipRent from "../../../question/questions/mortgage/survey/SharedOwnershipRent";
import HasExistingProperty from "../../../question/questions/mortgage/survey/HasExistingProperty";
import ExistingPropertyAddress from "../../../question/questions/mortgage/survey/ExistingPropertyAddress";
import ExistingPropertyOwnership from "../../../question/questions/mortgage/survey/ExistingPropertyOwnership";
import ExistingPropertyValue from "../../../question/questions/mortgage/survey/ExistingPropertyValue";
import ExistingPropertyStatus from "../../../question/questions/mortgage/survey/ExistingPropertyStatus";
import ExistingPropertyEncumbered from "../../../question/questions/mortgage/survey/ExistingPropertyEncumbered";
import ExistingPropertyBalance from "../../../question/questions/mortgage/survey/ExistingPropertyBalance";
import ExistingPropertyLender from "../../../question/questions/mortgage/survey/ExistingPropertyLender";
import ExistingPropertyEquityScheme from "../../../question/questions/mortgage/survey/ExistingPropertyEquityScheme";
import ExistingPropertyEquityLoanPercentage
  from "../../../question/questions/mortgage/survey/ExistingPropertyEquityLoanPercentage";
import ExistingPropertyShared from "../../../question/questions/mortgage/survey/ExistingPropertyShared";
import ExistingPropertySharedPercentage
  from "../../../question/questions/mortgage/survey/ExistingPropertySharedPercentage";
import ExistingPropertySharedRent from "../../../question/questions/mortgage/survey/ExistingPropertySharedRent";
import DepositSource from "../../../question/questions/mortgage/survey/DepositSource";
import EquityLoanPercentage from "../../../question/questions/mortgage/survey/EquityLoanPercentage";
import Savings from "../../../question/questions/mortgage/survey/Savings";
import ExistingPropertyCapitalRaisingReason
  from "@/components/common/questionnaires/question/questions/mortgage/survey/ExistingPropertyCapitalRaisingReason";
import SelfBuild from "@/components/common/questionnaires/question/questions/mortgage/survey/SelfBuild";
import RTB from "@/components/common/questionnaires/question/questions/mortgage/survey/RTB";
import RTBDiscount from "@/components/common/questionnaires/question/questions/mortgage/survey/RTBDiscound";
import Timescale from "@/components/common/questionnaires/question/questions/mortgage/survey/Timescale";
import BuyToLetMonthlyRent
  from "@/components/common/questionnaires/question/questions/mortgage/survey/BuyToLetMonthlyRent";
import DepositSourceDetailsFamily
  from "@/components/common/questionnaires/question/questions/mortgage/survey/DepositSourceDetailsFamily";
import DepositSourceDetailsOther
  from "@/components/common/questionnaires/question/questions/mortgage/survey/DepositSourceDetailsOther";

export default {
  name: 'General',
  mixins: [
    fieldsetHelpers,
    statusOptions
  ],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    DepositSourceDetailsOther,
    DepositSourceDetailsFamily,
    BuyToLetMonthlyRent,
    Timescale,
    RTBDiscount,
    RTB,
    SelfBuild,
    ExistingPropertyCapitalRaisingReason,
    Type,
    Usage,
    FTB,
    PropertyFound,
    Value,
    Balance,
    EquityScheme,
    SharedOwnership,
    SharedOwnershipPercentage,
    SharedOwnershipRent,
    HasExistingProperty,
    ExistingPropertyAddress,
    ExistingPropertyOwnership,
    ExistingPropertyValue,
    ExistingPropertyStatus,
    ExistingPropertyEncumbered,
    ExistingPropertyBalance,
    ExistingPropertyLender,
    ExistingPropertyEquityScheme,
    ExistingPropertyEquityLoanPercentage,
    ExistingPropertyShared,
    ExistingPropertySharedPercentage,
    ExistingPropertySharedRent,
    DepositSource,
    EquityLoanPercentage,
    Savings
  },
  data() {
    return {
      label: 'Mortgage Requirements', // used for steps progress
      videoUrl: 'https://dm-kernel-public.s3.eu-west-2.amazonaws.com/static/video/kernel_help/mortgage_survey_intro.mp4'

    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
