<template>
  <QuestionnaireBase :title="title" :form="form" :finishFunction="null">

    <template v-slot:default>
      <component :is="show" :key="1" :ref="show" v-model="form"/>

      <component
          v-if="load in $options.components"
          v-show="false"
          :is="load"
          key="load"
          :ref="load"
          v-model="form"
      />
    </template>
  </QuestionnaireBase>
</template>

<script>
import {loadHelpers} from "../loadHelpers";

import General from "../../../components/common/questionnaires/fieldsets/mortgage/survey/General";
import QuestionnaireBase from "../../../components/common/questionnaires/questionnaire";
import NameStatusMortgage from "../../../components/common/questionnaires/fieldsets/mortgage/NameStatusMortgage";
import ContactMortgage from "../../../components/common/questionnaires/fieldsets/mortgage/ContactMortgage";
import AddressFull from "../../../components/common/questionnaires/fieldsets/full/AddressFull";
import ChildrenDependantsMortgage
  from "../../../components/common/questionnaires/fieldsets/mortgage/ChildrenDependantsMortgage";
import AssetsMortgage from "../../../components/common/questionnaires/fieldsets/mortgage/AssetsMortgage";
import PoliciesMortgage from "../../../components/common/questionnaires/fieldsets/mortgage/PoliciesMortgage";
import LiabilitiesMortgage from "../../../components/common/questionnaires/fieldsets/mortgage/LiabilitiesMortgage";
import CommitmentsFull from "../../../components/common/questionnaires/fieldsets/full/CommitmentsFull";
import IncomeMortgage from "@/components/common/questionnaires/fieldsets/mortgage/IncomeMortgage";

export default {
  name: "MortgageSurveyPlusFactFind",
  mixins: [loadHelpers],
  components: {
    General,
    ContactMortgage,
    NameStatusMortgage,
    ChildrenDependantsMortgage,
    QuestionnaireBase,
    AddressFull,
    AssetsMortgage,
    PoliciesMortgage,
    LiabilitiesMortgage,
    CommitmentsFull,
    IncomeMortgage
  },
  data() {
    return {
      name: 'MortgageSurveyPlusFactFind',
      title: "Mortgage Pre Consultation Questions",
      url: 'mortgage_survey_plus_client',
      order: [ // imported components required here to load
        'General',
        'NameStatusMortgage',
        'ContactMortgage',
        'AddressFull',
        'ChildrenDependantsMortgage',
        'AssetsMortgage',
        'PoliciesMortgage',
        'LiabilitiesMortgage',
        'CommitmentsFull',
        'IncomeMortgage'
      ],
      expiryAccessCodeOnFinish: true

    };
  }
};
</script>
