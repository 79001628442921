<template>
  <QuestionBase :errors="[...serverErrors, ...jsErrors]"
                :question="question"
                :valid="!!data">
    <InputRadio v-model="data"
                :args="args"
                :db="db"
                :options="optionsNewEquity"
                :skipped="skipped"
                class="radio-options-wide"
                @jsErrors="jsErrors=$event"
                @serverErrors="serverErrors=$event"
                @skipped="skipped=$event" />
  </QuestionBase>
</template>

<script>
import {questionLoadHelpers} from "../../../helpers/questionLoadHelpers";
import {mortgageSurveyOptions} from "../../../options/mortgageSurveyOptions";

import QuestionBase from "../../../QuestionBase";
import InputRadio from "../../../inputs/InputRadio";

export default {
  name: 'EquityScheme',
  mixins: [
    mortgageSurveyOptions,
    questionLoadHelpers
  ],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: String,
      required: false
    },
    type: {
      type: String,
      required: false
    },
    ftb: {
      type: Boolean,
      required: false
    },
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    },
    question() {
      return {
        title: `${this.type === 'Remortgage' ? 'Will an equity loan scheme remain following the remortgage?' : 'Are you planning on using an equity loan scheme like Help to Buy?'}`,
        subTitle: null,
        tip: null,
      }
    },
    optionsNewEquity() {
      let list = [
        {
          label: 'No',
          value: 'No'
        }
      ];
      if (this.ftb) {
        list.push({
          label: 'Help to Buy',
          value: 'Help to Buy'
        },);
      }
      list.push({
        label: 'Other Scheme',
        value: 'Other Scheme'
      });
      return list;
    },
  },
  data() {
    return {
      db: {
        saveLocation: 'mortgage_survey_save',
        saveField: 'new_equity_loan',
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>